import { token } from '@/styled-system/tokens';
import { Fragment } from 'react';

export const highlightQueryMatch = (label: string, query: string) => {
	if (!query) {
		return label;
	}

	const parts = label.split(new RegExp(`(${query})`, 'gi'));
	const lowerCaseQuery = query.toLowerCase();

	return (
		<Fragment>
			{parts.map((part, index) => {
				const isMatch = part.toLowerCase() === lowerCaseQuery;

				return (
					<span key={`${index}-${part}`} style={isMatch ? { fontWeight: token('fontWeights.semibold') } : {}}>
						{part}
					</span>
				);
			})}
		</Fragment>
	);
};
