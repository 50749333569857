'use client';

import { Button } from '@/components/core/Button/Button';
import { Checkbox } from '@/components/core/Checkbox';
import { SearchDropdownVariants, searchDropdown } from '@/components/core/SearchDropdown/SearchDropdown.recipe';
import { highlightQueryMatch } from '@/components/core/SearchDropdown/SearchDropdown.utils';
import { Tags } from '@/components/core/Tags/Tags';
import { Text } from '@/components/core/Text/Text';
import { ChevronDownIcon } from '@/icons/ChevronDownIcon';
import { CloseIcon } from '@/icons/CloseIcon';
import { OkIcon } from '@/icons/OkIcon';
import { WarningIcon } from '@/icons/WarningIcon';
import { css } from '@/styled-system/css';
import { Box } from '@/styled-system/jsx';
import { SystemStyleObject } from '@/styled-system/types';
import { Combobox, ComboboxRootProps, Portal, createListCollection } from '@ark-ui/react';
import { debounce } from 'lodash';
import { FC, Fragment, RefObject, useState } from 'react';

export interface ISearchDropdownItem {
	label: string;
	value: string;
	disabled?: boolean;
	hidden?: boolean;
}

export interface ISearchDropdownProps extends Omit<ComboboxRootProps<ISearchDropdownItem>, 'onChange' | 'collection'> {
	items: Array<ISearchDropdownItem>;
	selectedItems?: Array<ISearchDropdownItem>;
	trackingRef?: RefObject<HTMLDivElement>;
	label?: string;
	helperText?: string;
	errorMessage?: string;
	noResultsLabel: string;
	onChange?: (params: {
		details: Combobox.ValueChangeDetails<ISearchDropdownItem>;
		selectedItem?: ISearchDropdownItem;
	}) => void;
	scrollingOverflow?: SearchDropdownVariants['scrollingOverflow'];
	size?: SearchDropdownVariants['size'];
	isError?: boolean;
	hidden?: boolean;
	readOnly?: boolean;
	readOnlyInput?: boolean;
	showSelectedTags?: boolean;
	showClearTrigger?: boolean;
	usePortal?: boolean;
	rootStyleProps?: SystemStyleObject;
	controlStyleProps?: SystemStyleObject;
	itemStyleProps?: SystemStyleObject;
	contentStyleProps?: SystemStyleObject;
}

export const SearchDropdown: FC<ISearchDropdownProps> = ({
	trackingRef,
	items,
	selectedItems,
	onChange,
	label,
	multiple,
	scrollingOverflow,
	size,
	isError,
	errorMessage,
	hidden,
	readOnly,
	readOnlyInput,
	value,
	disabled,
	helperText,
	noResultsLabel,
	rootStyleProps = {},
	controlStyleProps = {},
	itemStyleProps = {},
	contentStyleProps = {},
	showSelectedTags = true,
	showClearTrigger = true,
	usePortal = false,
	...rest
}) => {
	const classes = searchDropdown.raw({ size, multiple, isError, hidden, readOnly, disabled, scrollingOverflow });

	const [filteredItems, setFilteredItems] = useState(items);

	const [inputValue, setInputValue] = useState(
		() => items.find((item) => item?.value === value?.toString())?.label || ''
	);

	const handleInputValueChange = (e: Combobox.InputValueChangeDetails) => {
		const hasExactMatch = items.some((item) => item.label === e.inputValue);

		setInputValue(e.inputValue);

		if (hasExactMatch) {
			setFilteredItems(items);
		} else {
			const filtered = items.filter((item) => item.label.toLowerCase().includes(e.inputValue.toLowerCase()));

			setFilteredItems(filtered.length > 0 ? filtered : []);
		}
	};

	const debouncedInputValueChange = debounce(handleInputValueChange, 300);

	const handleValueChange = (details: Combobox.ValueChangeDetails<ISearchDropdownItem>) => {
		const selectedItem = items.find((item) => details.value.includes(item.value));

		onChange?.({ details, selectedItem });
	};

	const PositionerWrapper = usePortal ? Portal : Fragment;

	return (
		<Combobox.Root
			ref={trackingRef}
			className={css(classes.root, rootStyleProps)}
			collection={createListCollection({ items: filteredItems })}
			onInputValueChange={debouncedInputValueChange}
			onValueChange={handleValueChange}
			value={value}
			multiple={multiple}
			disabled={disabled}
			readOnly={readOnly}
			closeOnSelect={!multiple}
			positioning={{ gutter: 1, flip: false }}
			inputBehavior="autohighlight"
			openOnClick
			{...rest}
		>
			{label && (
				<Combobox.Label asChild>
					<Text as="label" size="xs" fontWeight="semibold">
						{label}
					</Text>
				</Combobox.Label>
			)}

			<Combobox.Control className={css(classes.control, controlStyleProps)}>
				{multiple && showSelectedTags ? (
					<Tags.Root className={css({ flexGrow: 1 })}>
						{selectedItems?.map((option, index) => {
							return (
								<Tags.Item key={`${option.value}-${index}`} className={css(classes.tag)}>
									<Tags.Label>{option?.label}</Tags.Label>
									<Tags.CloseButton
										as={Box}
										onClick={() => {
											const updatedSelectedItems = selectedItems.filter((item) => item.value !== option.value);
											const updatedValue = updatedSelectedItems.map((item) => item.value);

											onChange?.({ details: { value: updatedValue, items: updatedSelectedItems } });
										}}
									/>
								</Tags.Item>
							);
						})}
						<Combobox.Input className={css(classes.input)} size={5} readOnly={readOnlyInput} />
					</Tags.Root>
				) : (
					<Combobox.Input className={css(classes.input)} size={5} readOnly={readOnlyInput} />
				)}

				{showClearTrigger && (
					<Combobox.ClearTrigger className={css(classes.clearTrigger)}>
						<CloseIcon width="16px" height="16px" />
					</Combobox.ClearTrigger>
				)}

				<Combobox.Trigger className={css(classes.trigger)}>
					<ChevronDownIcon width="14px" height="14px" />
				</Combobox.Trigger>
			</Combobox.Control>

			<PositionerWrapper>
				<Combobox.Positioner>
					<Combobox.Content className={css(classes.content, contentStyleProps)}>
						<Combobox.ItemGroup className={css(classes.itemGroup)}>
							{filteredItems.length > 0 ? (
								filteredItems.map((item) =>
									multiple ? (
										<Combobox.Item key={item.value} item={item} hidden={item.hidden} asChild>
											<Button
												rootProps={css.raw(classes.item, itemStyleProps)}
												variant="unstyled"
												onClick={(e: MouseEvent) => e.preventDefault()}
											>
												<Checkbox
													label={highlightQueryMatch(item.label, inputValue)}
													disabled={item?.disabled}
													isChecked={selectedItems?.some((selectedItem) => selectedItem.value === item.value)}
												/>
											</Button>
										</Combobox.Item>
									) : (
										<Combobox.Item
											key={item.value}
											item={item}
											hidden={item.hidden}
											className={css(classes.item, itemStyleProps)}
										>
											<Combobox.ItemText>{highlightQueryMatch(item.label, inputValue)}</Combobox.ItemText>
											<Combobox.ItemIndicator>
												<OkIcon width="24px" height="24px" />
											</Combobox.ItemIndicator>
										</Combobox.Item>
									)
								)
							) : (
								<Box py="3" px="4">
									<Text size="sm" color="text.regular.subtitle">
										{noResultsLabel}
									</Text>
								</Box>
							)}
						</Combobox.ItemGroup>
					</Combobox.Content>
				</Combobox.Positioner>
			</PositionerWrapper>

			{helperText && (
				<Text size="xxs" className={css(classes.helperText)}>
					{helperText}
				</Text>
			)}

			{Boolean(isError) && (
				<Box className={css(classes.error)}>
					<WarningIcon width={12} height={12} />
					<Text size="xxs" className={classes.helperText}>
						{errorMessage}
					</Text>
				</Box>
			)}
		</Combobox.Root>
	);
};
