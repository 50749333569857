'use client';

import { ISearchDropdownProps, SearchDropdown } from '@/components/core/SearchDropdown/SearchDropdown';
import { css } from '@/styled-system/css';
import { FC } from 'react';

export const ArticleFilterSearchDropdown: FC<ISearchDropdownProps> = (props) => {
	return (
		<SearchDropdown
			multiple={true}
			showSelectedTags={false}
			scrollingOverflow="lg"
			rootStyleProps={css.raw({ flex: '1' })}
			itemStyleProps={css.raw({
				'& label': {
					whiteSpace: 'initial',
					textAlign: 'start',
				},
			})}
			{...props}
		/>
	);
};
