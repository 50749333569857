import type { FC, SVGAttributes } from 'react';

export const ChevronDownIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	const { width = '24px', height = '24px', ...rest } = props;

	return (
		<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...rest}>
			<path d="M26.5 11.48 25 9.98l-9 9.21-9-9.21-1.5 1.5L16 22.02l10.5-10.54Z" fill="currentColor" />
		</svg>
	);
};
