'use client';

import { ArticleSearchInput } from '@/components/block-templates/ArticleFilterBlock/components/ArticleSearchInput';
import { VisibilitySettings } from '@/components/block-templates/Jobs/JobFilterBlock/JobFilterBlockTemplate.utils';
import { ISearchDropdownItem } from '@/components/core/SearchDropdown/SearchDropdown';
import { areJobFiltersApplied } from '@/components/shared/Jobs/Jobs.utils';
import { JobFilterSearchDropdown } from '@/components/shared/Jobs/components/JobFilterSearchDropdown/JobFilterSearchDropdown';
import { FilterToggle } from '@/components/shared/filters/FilterToggle';
import useQueryParams from '@/hooks/useQueryParams';
import { css } from '@/styled-system/css';
import { Flex, Stack } from '@/styled-system/jsx';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';
import { omit } from 'lodash';
import { FC, useMemo, useState } from 'react';

export interface IJobsFilterProps {
	locations: Array<ISearchDropdownItem>;
	departments: Array<ISearchDropdownItem>;
	visibilitySettings: VisibilitySettings;
	translations: TranslationLabelValues;
}

export const JobFilter: FC<IJobsFilterProps> = ({ locations, departments, translations, visibilitySettings }) => {
	const [isFilterVisible, setIsFilterVisible] = useState(Boolean(!visibilitySettings.ShowSearchInput));

	const { query, change } = useQueryParams();

	const defaultDepartments = useMemo(() => {
		return departments.filter((department) => query?.departments?.includes(department.value));
	}, [departments, query]);

	const defaultLocations = useMemo(() => {
		return locations.filter((location) => query?.locations?.includes(location.value));
	}, [locations, query]);

	const changeFilter = (field: string, items: Array<ISearchDropdownItem> | null) => {
		if (!items) {
			return null;
		}

		const updateQuery = () => {
			if (items.length === 0) {
				change(omit(query, [field]));

				return;
			}
			change({ ...query, [field]: items.map((item) => item.value) });
		};

		// Check if the filter for this field already exists
		if (query?.[field]) {
			updateQuery();
		} else {
			// Create a new filter for this field
			change({ ...query, [field]: items.map((item) => item.value) });
		}
	};

	const dropdownFlex =
		[
			visibilitySettings.ShowSearchInput,
			visibilitySettings.ShowDivisionFilter,
			visibilitySettings.ShowLocationFilter,
		].filter(Boolean).length - 1 || 0;

	if (!visibilitySettings.ShowJobFilterBar) {
		return null;
	}

	return (
		<Stack
			w="100%"
			alignItems="flex-start"
			justifyContent="flex-start"
			direction={{ base: 'column', md: 'row' }}
			gap={4}
		>
			{visibilitySettings.ShowSearchInput && (
				<Flex
					className={css({
						gap: 4,
						w: {
							base: '100%',
							md: 'auto',
							lg: 'calc((100% - (var(--display-options-spacing-lg) * 2)) / 3)',
						},
					})}
				>
					<ArticleSearchInput
						placeholder={translate(translations, 'jobs.filter.query.placeholder')}
						defaultValue={query.search}
					/>
					<FilterToggle
						isActive={areJobFiltersApplied(query)}
						onClick={() => setIsFilterVisible((visible) => !visible)}
					/>
				</Flex>
			)}
			<Flex
				gap={4}
				flex={dropdownFlex}
				w="100%"
				display={{ base: isFilterVisible ? 'flex' : 'none', md: 'flex' }}
				flexDirection={{ base: 'column', md: 'row' }}
				alignItems="flex-start"
				justifyContent="flex-end"
			>
				{visibilitySettings.ShowDivisionFilter && (
					<JobFilterSearchDropdown
						placeholder={translate(translations, 'jobs.filter.division.placeholder')}
						noResultsLabel={translate(translations, 'dropdown.noResults')}
						onChange={({ details }) => changeFilter('departments', details.items)}
						items={departments}
						value={defaultDepartments.map((item) => item.value)}
						selectedItems={defaultDepartments}
					/>
				)}
				{visibilitySettings.ShowLocationFilter && (
					<JobFilterSearchDropdown
						placeholder={translate(translations, 'jobs.filter.location.placeholder')}
						noResultsLabel={translate(translations, 'dropdown.noResults')}
						onChange={({ details }) => changeFilter('locations', details.items)}
						items={locations}
						value={defaultLocations.map((item) => item.value)}
						selectedItems={defaultLocations}
					/>
				)}
			</Flex>
		</Stack>
	);
};
