import { ILinkProps } from '@/components/core/Link/Link';
import { isExternalLink } from '@/components/shared/Navigation/Navigation.utils';
import { ExternalIcon } from '@/icons/ExternalIcon';
import { poly } from '@/libs/poly';
import NextLink from 'next/link';
import { styled } from '@/styled-system/jsx';
import { css } from '@/styled-system/css';
import { logger } from '@/utils/logger';
import { MobileLinkInteractionWrapper } from '@/components/shared/MobileLinkInteractionWrapper/MobileLinkInteractionWrapper';

const ButtonLinkContainer = styled(poly(NextLink));

export const NavigationLeafLink = ({
	isActive,
	color: _color = 'text.regular.default',
	children,
	href,
	...rest
}: ILinkProps & { isActive?: boolean }) => {
	if (!href) {
		logger.warn('NavigationLeafLink: href is required');

		return null;
	}
	const isExternal = isExternalLink(href);

	return (
		<MobileLinkInteractionWrapper>
			{({ isInteracting }) => (
				<ButtonLinkContainer
					className={css({
						display: 'flex',
						fontSize: {
							base: '1rem',
							md: '1.25rem',
						},
						fontWeight: 'semibold',
						color: isActive || isInteracting ? 'actions.links.regular.default' : 'text.regular.default',
						borderColor: isActive || isInteracting ? 'actions.links.regular.default' : 'transparent',
						borderBottomWidth: {
							base: '2px',
							md: '3px',
						},
						borderBottomStyle: 'solid',
						letterSpacing: {
							base: '-0.015rem',
							md: '-0.025rem',
						},
						mb: '18px',
						// On touch devices like Safari on iPhones/iPads, hover effects can behave unexpectedly,
						// such as being applied when navigation stack changes due to touch interactions.
						// To prevent hover effects on touch-only devices, we use the '(hover: hover)' media query.
						// For more information check MobileLinkInteractionWrapper component.
						'@media (hover: hover)': {
							_hover: {
								color: 'actions.links.regular.default',
								borderBottomColor: 'actions.links.regular.default',
							},
						},
					})}
					w={isExternal ? '100%' : 'unset'}
					target={isExternal ? '_blank' : ''}
					justifyContent={isExternal ? 'space-between' : 'flex-start'}
					href={href}
					{...rest}
				>
					{children}
					{isExternal ? <ExternalIcon /> : undefined}
				</ButtonLinkContainer>
			)}
		</MobileLinkInteractionWrapper>
	);
};
