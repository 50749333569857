'use client';

import { TextSnippet } from '@/components/shared/TextSnippet/TextSnippet';
import { BackgroundColorVariant } from '@/enums/ColorMode';
import { SendIconWithBackground } from '@/icons/SendIconWithBackground';
import { WarningIcon } from '@/icons/WarningIcon';
import { RecipeVariant, RecipeVariantProps, sva } from '@/styled-system/css';
import { Box, BoxProps } from '@/styled-system/jsx';
import { PageContext } from '@/utils/pageContextProvider';
import { FC, forwardRef, useContext } from 'react';

export type FormMessageVariants = RecipeVariantProps<typeof formMessage>;

export type IFormMessageProps = BoxProps &
	FormMessageVariants & {
		content?: string;
	};

export const FormMessage = forwardRef<HTMLDivElement, IFormMessageProps>(({ variant, content, ...rest }, ref) => {
	if (!content) {
		return null;
	}

	const { siteName } = useContext(PageContext);
	const classes = formMessage({ variant, site: siteName as FormMessageVariant['site'] });

	return (
		<Box ref={ref} data-part="root" className={classes.root} {...rest}>
			<TextSnippet
				id={`salesforceFormMessageText${variant}`}
				content={content}
				dataId="form-message-text-snippet"
				preventAutomatedTracking
				parentBackgroundColor={BackgroundColorVariant.PRIMARY}
			/>
			<FormMessageIcon success={variant === 'success'} className={classes.icon} />
		</Box>
	);
});

const FormMessageIcon: FC<BoxProps & { success?: boolean }> = ({ success = false, ...rest }) => {
	return (
		<Box data-part="icon" {...rest}>
			{success ? <SendIconWithBackground /> : <WarningIcon />}
		</Box>
	);
};

const formMessage = sva({
	slots: ['root', 'icon'],
	base: {
		root: {
			width: 'full',
			borderRadius: 'sm',
			display: 'flex',
			gap: {
				base: '4',
				sm: '6',
			},
			flexDir: {
				base: 'column-reverse',
				sm: 'row',
			},
			alignItems: {
				base: 'start',
				sm: 'center',
			},
			padding: {
				base: '6',
				sm: '12',
			},
		},
		icon: {
			'& > svg': {
				base: {
					width: '54px',
					height: '54px',
				},
				md: {
					width: '64px',
					height: '64px',
				},
			},
		},
	},
	variants: {
		variant: {
			error: {
				root: {
					backgroundColor: 'trafficLights.error.background',
				},
				icon: {
					color: 'text.regular.default',
				},
			},
			success: {
				root: {
					backgroundColor: 'surface.interactive.card.secondary',
				},
				icon: {
					color: 'icons.inverted.default',
				},
			},
		},
		site: {
			vosti: {},
		},
	},
	compoundVariants: [
		{
			variant: 'success',
			site: 'vosti',
			css: {
				icon: {
					color: 'text.regular.default',
				},
			},
		},
	],
});

type FormMessageVariant = RecipeVariant<typeof formMessage>;
