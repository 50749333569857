import { NavigationLink } from '@/components/core/NavigationLink/NavigationLink';
import {
	NavigationItem,
	getActiveIndicesFromPathname,
	getNavigationSubNodes,
} from '@/components/shared/Navigation/Navigation.utils';
import { NavigationColumnContainer } from '@/components/shared/Navigation/components/NavigationColumnContainer';
import { NavigationLeafLink } from '@/components/shared/Navigation/components/NavigationLeafLink';
import { HStack, HstackProps } from '@/styled-system/jsx';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';
import { FC, useMemo, useState } from 'react';

interface INavigationContentProps extends HstackProps {
	data: Array<NavigationItem>;
	pathname: string;
	translations: TranslationLabelValues;
}

export const NavigationContentMobile: FC<INavigationContentProps> = ({ data, pathname, translations, ...rest }) => {
	// linkStack keeps an array of selected indexes
	// [0, 3] means that the activeItem is data.navigation[0].subNodes[3]
	const [linkStack, setLinkStack] = useState<Array<number>>(() => getActiveIndicesFromPathname(pathname, data));

	// add selected node index to the linkStack
	const handleNavLinkClick = (index: number) => {
		setLinkStack((linkStack) => [...linkStack, index]);
	};

	// remove last index from linkStack (make its parent activeItem)
	const handleBackClick = () => {
		setLinkStack((linkStack) => linkStack.filter((_item, i) => i !== linkStack.length - 1));
	};

	// this will recalculate which node is active when the linkStack changes
	const activeItem = useMemo(() => {
		const item = getNavigationSubNodes(linkStack, data) as NavigationItem;

		if (item?.subNodes?.length === 0) {
			const newStack = linkStack.slice(0, linkStack.length - 1);

			return getNavigationSubNodes(newStack, data) as NavigationItem;
		}

		return item;
	}, [data, linkStack]);

	// this is necessary because we need a list of links to render
	// but root level and subnodes have different structure
	// so, if there is no active item - take the list of root links
	// if there is an active item - take its subNodes
	const nodes = linkStack.length === 0 ? data : activeItem.subNodes;

	return (
		<HStack alignItems="flex-start" padding={0} {...rest}>
			<NavigationColumnContainer showDelimiter={false}>
				{linkStack.length !== 0 && (
					<NavigationLink
						width="100%"
						size="small"
						label={translate(translations, 'navigation.back', 'Back')}
						description={activeItem.name}
						orientation="left"
						borderColor="links.regular.default"
						onClick={(e: Event) => {
							e.stopPropagation();
							e.preventDefault();
							handleBackClick();
						}}
					/>
				)}
				{linkStack.length !== 0 && (
					<NavigationLink
						width="100%"
						size={{ base: 'small', sm: 'small', md: 'medium', lg: 'large' }}
						label={activeItem.name}
						as="a"
						orientation="none"
						description={activeItem.title}
						href={activeItem.url}
						isActive={pathname.endsWith(`/${activeItem.urlSegment}/`)}
					/>
				)}

				{nodes?.map((item, i) => {
					if (item.subNodes?.length === 0 || linkStack.length === 2) {
						return (
							<NavigationLeafLink key={item.url} href={item.url} isActive={pathname.endsWith(`/${item.urlSegment}/`)}>
								{item.name}
							</NavigationLeafLink>
						);
					}

					return (
						<NavigationLink
							key={item.url}
							width="100%"
							size={{ base: 'small', md: 'medium', lg: 'large' }}
							label={item.name}
							description={item.title}
							onClick={(e: Event) => {
								e.stopPropagation();
								e.preventDefault();
								handleNavLinkClick(i);
							}}
						/>
					);
				})}
			</NavigationColumnContainer>
		</HStack>
	);
};
