import { FC, SVGAttributes } from 'react';

export const OkIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	return (
		<svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M13.6706 21.7283L25.3145 10.0846L24.2376 9.00781L12.5938 20.6515L7.64958 15.7073L6.57275 16.7841L12.5938 22.8051L13.6706 21.7283Z"
				fill="currentColor"
			></path>
		</svg>
	);
};
