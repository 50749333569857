import { sva } from '@/styled-system/css';

export const accordionRecipe = sva({
	slots: ['root', 'trigger', 'item', 'content'],
	base: {
		root: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			divideY: '1px',
			borderRadius: 'md',
			overflow: 'hidden',
		},
		trigger: {
			p: 4,
			alignItems: 'center',
			background: 'transparent',
			cursor: 'pointer',
			display: 'flex',
			fontWeight: 'semibold',
			textAlign: 'left',
			justifyContent: 'space-between',
			textStyle: 'md',
			width: '100%',
			'&:not([data-expanded])': {
				pb: '4',
			},
		},
		item: {
			'&:not([hidden]) ~ :not([hidden]) > [data-part="trigger"]': {
				pt: '4',
			},
			_last: {
				_open: {
					'& > [data-part="item-content"]': {
						borderBottomRadius: 'md',
					},
				},
			},
		},
		content: {
			'&[data-state="open"]': {
				animation: 'slideInFromTop 0.15s ease-out',
			},
			borderStyle: 'solid',
			display: 'flex',
			flexDirection: 'column',
			gap: 4,
			px: 4,
			pt: 4,
			pb: 6,
		},
	},
	defaultVariants: {
		variant: 'light',
		size: 'lg',
	},
	variants: {
		size: {
			sm: {
				trigger: {
					fontSize: '0.875rem',
				},
			},
			lg: {
				trigger: {
					fontSize: '1.125rem',
				},
			},
		},
		variant: {
			light: {
				root: {
					background: 'surface.interactive.accordion.regular.fillDefault',
				},
				item: {
					borderColor: 'other.border.regular.default',
				},
				trigger: {
					color: 'text.regular.default',
					'&[data-state="open"]': {
						background: 'surface.interactive.accordion.regular.fillDefaultOpened',
					},
					_hover: {
						background: 'surface.interactive.accordion.regular.fillHover',
						'&[data-state="open"]': {
							background: 'surface.interactive.accordion.regular.fillHoverOpened',
						},
					},
				},
				content: {
					color: 'text.regular.default',
					'&[data-state="open"]': {
						borderBottomWidth: '2px',
						borderBottomColor: 'text.regular.default',
					},
				},
			},
			dark: {
				root: {
					background: 'transparent',
				},
				item: {
					borderColor: 'other.border.inverted.default',
				},
				trigger: {
					color: 'actions.primary.inverted.fillDefault',
					'&[data-state="open"]': {
						background: 'surface.interactive.accordion.inverted.fillDefaultOpened',
					},
					_hover: {
						background: 'surface.interactive.accordion.inverted.fillHover',
						'&[data-state="open"]': {
							background: 'surface.interactive.accordion.inverted.fillHoverOpened',
						},
					},
				},
				content: {
					color: 'text.inverted.default',
					'&[data-state="open"]': {
						borderBottomWidth: '2px',
						borderBottomColor: 'other.border.inverted.focus',
					},
				},
			},
			sand: {
				root: {
					background: 'surface.interactive.accordion.sand.fillDefault',
				},
				item: {
					borderColor: 'other.border.sand.hairline',
				},
				trigger: {
					color: 'text.regular.default',
					'&[data-state="open"]': {
						background: 'surface.interactive.accordion.sand.fillDefaultOpened',
					},
					_hover: {
						background: 'surface.interactive.accordion.sand.fillHover',
						'&[data-state="open"]': {
							background: 'surface.interactive.accordion.sand.fillHoverOpened',
						},
					},
				},
				content: {
					color: 'text.regular.default',
					'&[data-state="open"]': {
						borderBottomWidth: '2px',
						borderBottomColor: 'other.border.sand.weak',
					},
				},
			},
		},
	},
});
