import { ISearchDropdownItem } from '@/components/core/SearchDropdown/SearchDropdown';
import React from 'react';

const LOCAL_STORAGE_KEY = 'disclaimerCache';

export type IUseDisclaimerCacheReturnType = {
	cachedValues: null | {
		isUSCitizen: boolean;
		selectedvisitorRole?: ISearchDropdownItem;
		selectedDomicile?: ISearchDropdownItem;
	};
	updateCache: (values: IUseDisclaimerCacheReturnType['cachedValues']) => void;
	clearCache: () => void;
};

export const useDisclaimerCache = () => {
	const [cachedValues, setCachedValues] = React.useState<IUseDisclaimerCacheReturnType['cachedValues']>(() => {
		if (typeof window === 'undefined') {
			return null;
		}

		const cachedValues = sessionStorage.getItem(LOCAL_STORAGE_KEY);

		if (cachedValues) {
			return JSON.parse(cachedValues);
		}

		return null;
	});

	const updateCache = (values: IUseDisclaimerCacheReturnType['cachedValues']) => {
		sessionStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(values));
		setCachedValues(values);
	};

	const clearCache = () => {
		sessionStorage.removeItem(LOCAL_STORAGE_KEY);
	};

	return {
		cachedValues,
		updateCache,
		clearCache,
	};
};
