import { css } from '@/styled-system/css';
import { useOutsideClick } from '@/utils/handleOutsideClick';
import React, { useCallback, useState } from 'react';

interface MobileLinkInteractionWrapperProps {
	children: (props: { isInteracting: boolean }) => React.ReactNode;
}

/**
 * MobileLinkInteractionWrapper
 *
 * This component is designed to address a specific edge case on mobile devices, particularly in Safari,
 * where hover styles may persist unexpectedly after tapping on interactive elements like links.
 * This happens when the user clicks on lower level of NavigationContent.mobile links stack,
 * and another component is render in the same place. This edge case is currently also covered in NavigationLeafLink component and navigationLink recipe _hover styles.
 *
 * The `isInteracting` state is used to manage whether the link is currently being interacted with.
 * This custom interaction state ensures proper behavior on touch devices that do not support hover.
 *
 * Key Features:
 * - Checks if the device supports `hover` using `window.matchMedia('(hover: hover)')`.
 *   If hover is supported, no custom interaction state is applied.
 * - Uses `useOutsideClick` to reset the `isInteracting` state when a click occurs outside the wrapper.
 * - Allows child components to access the `isInteracting` state via a render-prop pattern for dynamic styling
 *   or behavior adjustments.
 *
 * This wrapper is especially useful for `NextLink` components or other links on mobile devices where
 * native hover behaviors can lead to inconsistent UI states.
 */
export const MobileLinkInteractionWrapper = ({ children }: MobileLinkInteractionWrapperProps) => {
	const wrapperRef = React.useRef<HTMLDivElement>(null);
	const [isInteracting, setIsInteracting] = useState(false);

	useOutsideClick(wrapperRef, () => {
		setIsInteracting(false);
	});

	const onClickHandler = useCallback(() => {
		const isHoverSupported = window.matchMedia('(hover: hover)').matches;

		if (isHoverSupported) {
			return;
		}

		setIsInteracting(true);
	}, []);

	return (
		<div
			onClick={onClickHandler}
			className={css({
				display: 'contents',
			})}
			ref={wrapperRef}
		>
			{children({ isInteracting })}
		</div>
	);
};
