'use client';

import { ISearchDropdownProps, SearchDropdown } from '@/components/core/SearchDropdown/SearchDropdown';
import { ISelectionItemResponse } from '@/interfaces/blocks/forms';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';
import { ChangeEvent, FC, RefObject, useMemo } from 'react';
import { FieldError } from 'react-hook-form';

interface IFormSearchDropdownProps
	extends Omit<ISearchDropdownProps, 'defaultValue' | 'onChange' | 'items' | 'value' | 'noResultsLabel'> {
	selectionItems: Array<ISelectionItemResponse>;
	defaultValue: string;
	value: string;
	error?: FieldError;
	isError?: boolean;
	trackingRef: RefObject<HTMLDivElement>;
	translations: TranslationLabelValues;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const FormSearchDropdown: FC<IFormSearchDropdownProps> = ({
	required,
	label,
	selectionItems,
	defaultValue,
	error,
	trackingRef,
	translations,
	onChange,
	name,
	value,
	...rest
}) => {
	const optionalLabel = translations ? ` (${translate(translations, 'form.optional', 'optional')})` : ` (optional)`;

	const dropdownItems = useMemo(() => {
		return selectionItems
			?.filter((item) => item?.contentLink?.expanded?.value)
			.map((item) => {
				const expanded = item?.contentLink?.expanded;

				return {
					value: expanded?.value,
					hidden: expanded?.isHidden,
					label: expanded?.textOrLabel || expanded?.value,
				};
			});
	}, [selectionItems]);

	const valueArray = typeof value === 'string' && value !== '' ? value.split(',') : [];
	const selectedDropdownItems = dropdownItems.filter((item) => valueArray.indexOf(item.value) >= 0);

	return (
		<SearchDropdown
			size="md"
			trackingRef={trackingRef}
			name={name}
			ids={{ input: name }}
			value={valueArray}
			items={dropdownItems}
			selectedItems={selectedDropdownItems}
			label={`${label} ${required ? ' *' : optionalLabel}`}
			noResultsLabel={translate(translations, 'dropdown.noResults')}
			onChange={({ details }) =>
				onChange?.({ target: { value: details?.value?.join(',') } } as ChangeEvent<HTMLInputElement>)
			}
			errorMessage={error?.message}
			required={required}
			{...rest}
		/>
	);
};
