import { ISearchDropdownItem } from '@/components/core/SearchDropdown/SearchDropdown';
import useQueryParams from '@/hooks/useQueryParams';
import { ArticleFiltersReturnType, FilterBarOptions } from '@/types/article-filter';
import { omit } from 'lodash';
import { useMemo } from 'react';

export const useArticleFilters = (filters: FilterBarOptions) => {
	const { query, change } = useQueryParams();

	const { topicCategories = [], investorTypeCategories = [], years = [] } = filters || {};

	const topicCategoryOptions = useMemo(() => {
		return topicCategories.map((category) => ({
			label: category.name,
			value: `${category.id}`,
		}));
	}, [topicCategories]);

	const investorTypeCategoryOptions = useMemo(() => {
		return investorTypeCategories.map((category) => ({
			label: category.name,
			value: `${category.id}`,
		}));
	}, [topicCategories]);

	const yearsOptions = useMemo(() => {
		return years.map((year) => ({ label: `${year}`, value: `${year}` }));
	}, [topicCategories]);

	const defaultTopic = useMemo(() => {
		return topicCategoryOptions.filter((category) => query?.category?.includes(category.value));
	}, [topicCategoryOptions, query]);

	const defaultInvestorType = useMemo(() => {
		return investorTypeCategoryOptions.filter((category) => query?.category?.includes(category.value));
	}, [investorTypeCategoryOptions, query]);

	const defaultYears = useMemo(() => {
		return yearsOptions.filter((year) => query?.year?.includes(year.value));
	}, [yearsOptions, query]);

	const changeFilter = (field: string, items: Array<ISearchDropdownItem> | null) => {
		if (!items) {
			return null;
		}

		const updateQuery = () => {
			if (items.length === 0) {
				change(omit(query, [field]));

				return;
			}
			change({ ...query, [field]: items.map((item) => item.value) });
		};

		// Check if the filter for this field already exists
		if (query?.[field]) {
			updateQuery();
		} else {
			// Create a new filter for this field
			change({ ...query, [field]: items.map((item) => item.value) });
		}
	};

	return {
		get defaults() {
			return {
				defaultTopic,
				defaultInvestorType,
				defaultYears,
			};
		},
		get options() {
			return {
				topicCategoryOptions,
				investorTypeCategoryOptions,
				yearsOptions,
			};
		},
		get changeFilter() {
			return changeFilter;
		},
	} as ArticleFiltersReturnType;
};
